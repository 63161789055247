import React from "react";

const WhatWeOffer = () => {
  return (
    <section id="whatweoffer" className="container">
      <h2>What We Offer</h2>
      <p>
        Our goal is to empower small to medium businesses for tomorrow’s world.
        We offer personalized support & real-time advice during each project
        with efficiency, speed, and compliance. Our services include:
      </p>
      <ul>
        <li> Web Development</li>
        <li>Mobile App Development (iOS & Android)</li>
        <li>Web Design</li>
        <li>Machine Learning and AI</li>
        <li>Online Platforms and Web Apps</li>
        <li>Branding & Full Digital Marketing</li>
        <li>
          Full Suite Graphic Design and AuthoringBespoke Software & Application
          Development
        </li>
        <li>E-Commerce & Online Retail Solutions and much more</li>
      </ul>
      {/* <button>Quick Consultation...</button> */}
    </section>
  );
};

export default WhatWeOffer;
