import React from "react";

const About = () => {
  return (
    <section id="about" style={{ backgroundImage: "url(images/about_bg.jpg)" }}>
      <div className="container content">
        <div className="box">
          <img src="images/about-us-bullingdon.jpg" alt="about Bulligndon" />
        </div>
        <div>
          <h2>Discover Bullingdon Digital</h2>

          <p>
            Bullingdon Digital is a digital agency based in the UK focused on
            helping all lines of business affected by Covid-19 to rebrand their
            business and transform new ideas into the digital age. Our team
            comprises a group of professional experts in digital solutions
            providing personalized support throughout the project, from
            collecting the information, until the approval and launch of the
            product. For us at Bullingdon Digital, the business idea of our
            clients is not a common project, their projects are unique and
            tailored to the client’s needs, therefore, they require specialized
            attention and commitment until their ideas become a reality.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
