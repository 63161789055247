import About from "./components/About";
import ContactUs from "./components/ContactUs";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Projects from "./components/Projects";
import Services from "./components/Services";
import Team from "./components/Team";
import WhatWeOffer from "./components/WhatWeOffer";
import WhyChooseUs from "./components/WhyChooseUs";

function App() {
  return (
    <>
      <Header />
      <About />
      <Services />
      <WhatWeOffer />
      <WhyChooseUs />
      <Team />
      <Projects />
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
