import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <section id="services" className="container">
      <h2>Services</h2>
      <Slider {...settings}>
        <div className="boxService">
          <img src="images/web_design.jpg" alt="Web Design" />
          <h2>Web Design</h2>
          <p>
            We are a full-service digital agency with the creative and technical
            capabilities to deliver full lifecycle web development for a
            dynamics array of businesses including Start-ups, Medium businesses,
            Banking, Consultancy, Charities Government, and others
          </p>
        </div>
        <div className="boxService">
          <img
            src="images/web_development.jpg"
            alt="Online Platforms and Web Apps"
          />
          <h2>Online Platforms and Web Apps</h2>
          <p>
            We can build deploy and host web applications globally supporting
            multi-language and multi-device options.
          </p>
        </div>
        <div className="boxService">
          <img
            src="images/mobile_apps.jpg"
            alt="Mobile Application Development (iOS & Android)"
          />
          <h2>Mobile Application Development (iOS & Android)</h2>
          <p>
            With our dedicated Butterfly methodology, we will be able to deliver
            a tailored solution at scale whilst harnessing the latest Web 3.0
            technologies
          </p>
        </div>
        {/* end first row */}
        <div className="boxService">
          <img src="images/ai_development.jpg" alt="Machine Learning and AI" />
          <h2>Machine Learning and AI</h2>
          <p>
            We are always on the leading edge of new technologies, and we have
            embedded AI into the very fabric of our design methodology
          </p>
        </div>
        <div className="boxService">
          <img
            src="images/graphic_design.jpg"
            alt="Full Suite Graphic Design and Authoring"
          />
          <h2>Full Suite Graphic Design and Authoring</h2>
          <p>
            Our team of sophisticated design professionals will take you through
            our dedicated client on-boarding process bringing us closer than
            ever before to your core needs. Developing brands and marketing
            material that touches the very soul of the target market and or
            clients
          </p>
        </div>
        <div className="boxService">
          <img
            src="images/software_development.jpg"
            alt="Software Development"
          />
          <h2>Bespoke Software & Application Development</h2>
          <p>
            Sometimes the only way to truly achieve the fully immersive digital
            solution for our clients is to build from scratch. We have
            successfully done fully bespoke projects from clients globally
          </p>
        </div>
        {/* end second row */}
        <div className="boxService">
          <img
            src="images/qa-testing.jpg"
            alt="Branding & Full Digital Marketing Agency"
          />
          <h2>Branding & Full Digital Marketing Agency​</h2>
          <p>
            As the world moves to digital having access to a full services
            agency reduces the time to deployment for campaigns and new product
            launches so why not try our branding services which provide Global
            reach and maximum digital impact
          </p>
        </div>
        <div className="boxService">
          <img
            src="images/ecommerce.jpg"
            alt="Ecommerce & Online Retail Solutions"
          />
          <h2>Ecommerce & Online Retail Solutions</h2>
          <p>
            We pride ourselves in delivering successful retail solutions that
            fully leverage the tools and capabilities provided by Web3.0 and
            beyond. We have built retail platforms for several UK institutions
            and Local councils. Why not let us demonstrate the power of ORS –
            Online retail solutions to your business.
          </p>
        </div>
      </Slider>
    </section>
  );
};

export default Services;
