import React from "react";

const DisplayVideo = ({ WatchVideo, setVideo }) => {
  const closeVideoFrame = () => {
    setVideo(false);
  };

  return (
    <section id="video" className={WatchVideo ? "d-flex" : "d-none"}>
      <div className="containerVideo">
        <i
          className="fas fa-times-circle"
          id="closeVideo"
          onClick={closeVideoFrame}
        ></i>
        <iframe
          id="frame"
          src="https://www.youtube.com/embed/ErIuvnFkJdI?start=2"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="YouTube video player"
        ></iframe>
      </div>
    </section>
  );
};

export default DisplayVideo;
