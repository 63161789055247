import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import ChatBot from "./ChatBot";

const Footer = () => {
  const [arrowTop, setArrowTop] = useState(false);
  const DisplayArrowTop = () => {
    window.addEventListener("scroll", () => {
      let y = window.scrollY;
      if (y > 100) {
        setArrowTop(true);
      } else {
        setArrowTop(false);
      }
    });
  };
  useEffect(() => {
    DisplayArrowTop();
  }, [arrowTop]);
  return (
    <footer className="container-full">
      <div className="content container">
        <div className="detail">
          <p>BullingdonDigital All Copyright © 2022 Reserved </p>
        </div>
        <div className="detail">
          <i className="fas fa-map-marker-alt icon"></i>
          <p> Kemp House, 160 City Rd, London EC1V 2NX </p>
        </div>
        <div className="detail">
          <i className="fas fa-phone icon"></i>
          <p>+44 020 7689 7888</p>
        </div>
        <div className="detail">
          <i className="fas fa-envelope icon"></i>
          <p>info@bullingdondigital.com</p>
        </div>

        <div className="social-media">
          <i
            className="fab fa-linkedin icon"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/bullingdon-digital/about/",
                "_blank"
              );
            }}
          ></i>

          <i
            className="fab fa-facebook-square icon"
            onClick={() => {
              window.open(
                "https://www.facebook.com/Bullingdon-Digital-107350211874194",
                "_blank"
              );
            }}
          ></i>

          <i
            className="fab fa-instagram icon"
            onClick={() => {
              window.open(
                "https://www.instagram.com/bullingdondigital/",
                "_blank"
              );
            }}
          ></i>
        </div>
      </div>
      <i
        className={arrowTop ? "fas fa-arrow-alt-circle-up d-block" : "d-none"}
        id="arrow-top"
        onClick={() => {
          scroll.scrollToTop();
        }}
      ></i>
      <ChatBot showChat={arrowTop} />
    </footer>
  );
};

export default Footer;
