import React from "react";

const Team = () => {
  return (
    <section id="team" className="container">
      <h2 className="title1"> Meet Our Team Members</h2>
      <h2 className="title2">Our Leadership Team</h2>
      <div className="members">
        <div>
          <img src="images/rexford.png" alt="Rexford" />
          <h2>Rexford Abbey</h2>
          <p>PARTNER</p>
        </div>
        <div>
          <img src="images/Alishba_black_white.jpg" alt="Alishba" />
          <h2>Alishba Qazi</h2>
          <p>CHIEF MARKETING OFFICER</p>
        </div>
        <div>
          <img src="images/victor_white_black.jpg" alt="Victor Reyes" />
          <h2>Victor Reyes</h2>
          <p>CHIEF TECHNOLOGY OFFICER</p>
        </div>
      </div>
    </section>
  );
};

export default Team;
