import React from "react";

const WhyChooseUs = () => {
  return (
    <section
      id="WhyChooseUs"
      style={{ backgroundImage: "url(images/why-choose-us.png)" }}
    >
      <div className="image">
        <img src="images/choose-us.png" alt="Why Choose Us" />
      </div>
      <div className="content">
        <h2> Why Choose Us</h2>
        <p>
          We are big enough to scale to your needs and we are small enough to
          care about each and every client/customer.
        </p>
        <div>
          <i className="fas fa-check-circle"></i> Collaborate with a team of
          field experts.
        </div>
        <div>
          <i className="fas fa-check-circle"></i> Become leaders of the market
          based on your unique idea.
        </div>
        <div>
          <i className="fas fa-check-circle"></i> Get real-time & flexible
          advice about your business.
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
