import { useState } from "react";
import { useForm } from "react-hook-form";
const ContactUs = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [confirm, setConfirm] = useState(false);
  const sendEmail = (dataform) => {
    if (dataform) {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          name: dataform.name,
          email: dataform.email,
          phone: dataform.phonenumber,
          subject: dataform.subject,
          comments: dataform.comments,
        }),
        headers: {
          "content-type": "application/json",
        },
      };
      fetch("https://bullingdondigital.com/server", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.success) {
            setConfirm(true);
            reset();
            setTimeout(() => {
              setConfirm(false);
            }, 5000);
          } else {
            alert("error occured while sending mail");
          }
        });
    }
  };

  return (
    <section
      id="contact"
      className="container-full"
      style={{ backgroundImage: "url(images/contact-bg.jpg)" }}
    >
      <h2 className="title1">Contact Us</h2>
      <h2 className="title2">Tell Us About Your Project</h2>
      <h2 className="title1">Over A Digital Coffe</h2>
      <div className="content container">
        <div>
          <img src="images/contact.jpg" alt="contact us" />
        </div>
        <div>
          <form onSubmit={handleSubmit(sendEmail)}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              placeholder="Your Name..."
              {...register("name", {
                required: true,
                maxLength: 20,
                pattern: /^[a-záéíóúüñ' ]+$/i,
              })}
            />
            {errors.name?.type === "required" && (
              <p className="text-red mb-1">Name is required...</p>
            )}
            {errors.name?.type === "maxLength" && (
              <p className="text-red mb-1">
                Name exceds the limit of 20 characters...
              </p>
            )}
            {errors.name?.type === "pattern" && (
              <p className="text-red mb-1">Invalid format only letters...</p>
            )}

            <label htmlFor="email">Email:</label>
            <input
              type="text"
              placeholder="Your Email..."
              {...register("email", {
                required: true,
                maxLength: 50,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
              })}
            />
            {errors.email?.type === "required" && (
              <p className="text-red mb-1">Email is required...</p>
            )}
            {errors.email?.type === "maxLength" && (
              <p className="text-red mb-1">
                Email exceds the limit of 50 characters...
              </p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="text-red mb-1">Invalid format...</p>
            )}

            <label htmlFor="phonenumber">Phone Number:</label>
            <input
              type="text"
              placeholder="Yor Phone Number..."
              {...register("phonenumber", {
                required: true,
                maxLength: 20,
                pattern: /^[0-9 ]+$/i,
              })}
            />
            {errors.phonenumber?.type === "required" && (
              <p className="text-red mb-1">Phone Number is required...</p>
            )}
            {errors.phonenumber?.type === "maxLength" && (
              <p className="text-red mb-1">
                Phone Number exceds the limit of 20 characters...
              </p>
            )}
            {errors.phonenumber?.type === "pattern" && (
              <p className="text-red mb-1">Invalid format, only numbers...</p>
            )}
            {/* end phone number */}
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              placeholder="Subject..."
              {...register("subject", {
                required: true,
                maxLength: 150,
                pattern: /^[a-záéíóúüñ' ]+$/i,
              })}
            />
            {errors.subject?.type === "required" && (
              <p className="text-red mb-1">Subject is required...</p>
            )}
            {errors.subject?.type === "maxLength" && (
              <p className="text-red mb-1">
                Subject exceds the limit of 150 characters...
              </p>
            )}
            {/* end subject */}
            <label htmlFor="comments">Comments:</label>
            <textarea
              placeholder="Your Comments..."
              {...register("comments", {
                required: true,
                maxLength: 500,
              })}
            />
            {errors.comments?.type === "required" && (
              <p className="text-red mb-1">Comments is required...</p>
            )}
            {errors.comments?.type === "maxLength" && (
              <p className="text-red mb-1">
                Comments exceds the limit of 500 characters...
              </p>
            )}
            {confirm ? (
              <div className="send-email">The email was sent successfully</div>
            ) : (
              ""
            )}
            <button type="submit">
              <i className="fas fa-arrow-alt-circle-right icon"></i> Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
