import React from "react";

const Projects = () => {
  return (
    <section
      id="projects"
      style={{ backgroundImage: "url(images/projects-bg.jpg)" }}
    >
      <div className="content container">
        <h2 className="title1">PROJECTS OVERVIEW</h2>
        <h2 className="title2">Some of Our Latest Projects</h2>
        <div className="container-projects">
          <div>
            <i className="fas fa-chart-bar icon"></i>
            <h2>Bidhive </h2>
          </div>
          <div>
            <i className="fas fa-shopping-cart icon"></i>
            <h2>Localfete</h2>
          </div>
          <div>
            <i className="fas fa-flag-usa icon"></i>
            <h2>Discovery Channel USA </h2>
          </div>
          <div>
            <i className="fas fa-user-nurse icon"></i>
            <h2>Fine-Fittle </h2>
          </div>
          <div>
            <i className="fas fa-graduation-cap icon"></i>
            <h2>Get Future Skills </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
