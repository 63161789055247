import React, { useState } from "react";
import ChatBot from "react-simple-chatbot";
//const ChatBot = ({ showChat }) => {
export default function Chatbot({ showChat }) {
  const [chat, setChat] = useState(false);
  const displayChat = () => {
    setChat(!chat);
  };
  return (
    <>
      <section id="containerchat" className={chat ? "d-block" : "d-none"}>
        <ChatBot
          steps={[
            {
              id: "1",
              message:
                "Hello this is Robert from Bullingdon,  may I ask your name please?",
              trigger: "2",
            },
            {
              id: "2",
              user: true,
              trigger: "3",
            },
            {
              id: "3",
              message: "Hi {previousValue}, nice to meet you!",
              trigger: "4",
            },
            {
              id: "4",
              message: "Now your email please?",
              trigger: "5",
            },
            {
              id: "5",
              user: true,
              trigger: "6",
            },

            {
              id: "6",
              message: "Thank you",
              trigger: "7",
            },
            {
              id: "7",
              message:
                "Just in case we lost communication, can I ask your phone number?",
              trigger: "8",
            },
            {
              id: "8",
              user: true,
              trigger: "9",
            },
            {
              id: "9",
              message: "Great,",
              trigger: "10",
            },
            {
              id: "10",
              message: "Now how can i help you?",
              trigger: "11",
            },
            {
              id: "11",
              user: true,
              trigger: "12",
            },
            {
              id: "12",
              message: "Yes we have that service",
              trigger: "13",
            },
            {
              id: "13",
              message:
                "Would you like to have a call with us to talk about your project?",
              trigger: "14",
            },
            {
              id: "14",
              options: [
                { value: 1, label: "Yes", trigger: "15" },
                { value: 0, label: "No", trigger: "16" },
              ],
            },
            {
              id: "15",
              message:
                "Please tell us the day and time that you will be available for the call",
              trigger: "16",
            },
            {
              id: "16",
              user: true,
              trigger: "17",
            },
            {
              id: "17",
              message:
                "Thank you very much for your time, We will contact you.",
              trigger: "18",
            },
            {
              id: "18",
              message: "Have a nice day :)",
              end: true,
            },
            {
              id: "19",
              message:
                "Very well, then you can find us or contact us at localfete@support.com",
              end: true,
            },
          ]}
        />
      </section>
      <i
        className={showChat ? "fas fa-comment-alt d-block" : "d-none"}
        id="chatbot"
        onClick={displayChat}
      ></i>
    </>
  );
}
