import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import DisplayVideo from "./DisplayVideo";

const Header = () => {
  const [bgColour, setBgColour] = useState(false);
  const [video, setVideo] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const changeBackground = () => {
    window.addEventListener("scroll", () => {
      let y = window.scrollY;
      if (y > 100) {
        setBgColour(true);
      } else {
        setBgColour(false);
      }
    });
  };

  useEffect(() => {
    changeBackground();
  }, [bgColour]);
  // end change background colour menu
  const displayVideo = () => {
    setVideo(true);
  };
  // end displayVideo
  const displayMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <>
      <i
        className="fas fa-bars"
        id="openMenuMobile"
        onClick={displayMobileMenu}
      ></i>
      <div id="containerMobile" className={mobileMenu ? "margin-right" : ""}>
        <i
          className="fas fa-times-circle"
          id="closeMenuMobile"
          onClick={displayMobileMenu}
        ></i>
        <img
          src="images/BullingdonDigital-logo.png"
          alt="Bullingdon Digital logo"
          width="200"
        />
        <div className="menu-list">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-79}
            duration={500}
            onClick={displayMobileMenu}
          >
            Home
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-79}
            duration={500}
            onClick={displayMobileMenu}
          >
            About Us
          </Link>
          <Link
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={-79}
            duration={500}
            onClick={displayMobileMenu}
          >
            Services
          </Link>
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-79}
            duration={500}
            onClick={displayMobileMenu}
          >
            Contact Us
          </Link>
        </div>
      </div>
      <header
        className={bgColour ? "container-full bg-header" : "container-full"}
      >
        <i className="fas fa-bars d-none" id="menuMobile"></i>
        <nav id="menu" className="container">
          <img
            src="images/BullingdonDigital-logo.png"
            alt="Bullingdon Digital logo"
            width="200"
          />
          <div className="menu-list">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-79}
              duration={500}
            >
              Home
            </Link>
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-79}
              duration={500}
            >
              About Us
            </Link>
            <Link
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-79}
              duration={500}
            >
              Services
            </Link>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-79}
              duration={500}
            >
              Contact Us
            </Link>
          </div>
        </nav>
      </header>
      <section
        id="home"
        style={{ backgroundImage: "url(images/banner-header.jpg)" }}
      >
        <div className="content">
          <h2 className="title1"> We transform your idea </h2>
          <h1 className="title2">
            Empowering your business for tomorrow’s world
          </h1>
          <i className="fas fa-caret-circle-right"></i>
          <div className="playButton" onClick={displayVideo}>
            <i className="fas fa-play-circle icon"></i>
          </div>
        </div>
        <DisplayVideo WatchVideo={video} setVideo={setVideo} />
      </section>
    </>
  );
};

export default Header;
